<template>
    <div style="width: 60%;margin: auto; cursor: zoom-in">
        <viewer @inited="onInited" class="viewer" ref="viewer" :options="{navbar: false}" :images="attachment_list">

            <template slot-scope="scope">
                <div class="ui bordered fluid medium images">
                    <img v-for="src in scope.images" :src="server_address + src.path" :key="src._id">
                </div>
            </template>
        </viewer>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'

    export default {
        name: "test",
        props:{
            image_src: {
                type: String,
                default: ''
            },
            image_type: {
                type: String,
                default: ''
            },
            image_id_doc: {
                type: String,
                default: ''
            }
        },
        data(){
            return{
                attachment_list: []
            }
        },
        methods:{
            onInited(viewer){
                viewer.show()
            },
            getAttachmentList() {
                log('getAttachmentList');
                const data = {
                    collection: this.$SHARED.collections.attachment,
                    query: {
                        id_doc: this.image_id_doc,
                        type: this.image_type,
                        path: {$ne: this.image_src}
                    },
                    options: {page: 1, limit: -1, sort: {date_creation: -1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.attachment_list = ar.docs;
                    this.attachment_list.unshift({path: this.image_src})
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        computed:{
            server_address(){
                return this.$store.state.SERVER_ADDRESS + '/';
            }
        },
        mounted() {
            this.getAttachmentList();
        }
    }
</script>

<style>
    .viewer-prev, .viewer-play, .viewer-next{
        /*display: none !important;*/
    }
</style>